import React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"
import './layout.css'
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Layout = ({ location, title, children, hideHeader }) => {

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(.4),
          lineHeight: '1.1',
          marginBottom: rhythm(2),
          marginTop: 0,
          fontWeight: 900
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
            display: 'flex',
            alignItems: 'center'
          }}
          to={`/`}
        >
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            alt="Alex Sidorenko"
            style={{
              marginRight: rhythm(1 / 2),
              marginBottom: 0,
              minWidth: 50,
              borderRadius: `100%`,
              backgroundColor: '#f0f0f0'
            }}
            imgStyle={{
              borderRadius: `50%`,
            }}
          />
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, Arial Black, sans-serif`,
          fontWeight: 900,
          marginTop: 0,
          marginBottom: '1rem',
          color: 'hsla(0,0%,0%,0.3)'
        }}
      >
        
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
            display: 'flex',
            alignItems: 'center'
          }}
          to={`/`}
        >
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            alt="Alex Sidorenko"
            style={{
              marginRight: rhythm(1 / 2),
              marginBottom: 0,
              minWidth: 50,
              borderRadius: `100%`,
              backgroundColor: '#f0f0f0'
            }}
            imgStyle={{
              borderRadius: `50%`,
            }}
          />
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      {!hideHeader && <header>{header}</header>}
      <main>{children}</main>
    </div>
  )
}

export default Layout
